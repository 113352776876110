// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-components-career-js": () => import("./../src/pages/components/career.js" /* webpackChunkName: "component---src-pages-components-career-js" */),
  "component---src-pages-components-education-js": () => import("./../src/pages/components/education.js" /* webpackChunkName: "component---src-pages-components-education-js" */),
  "component---src-pages-components-skills-js": () => import("./../src/pages/components/skills.js" /* webpackChunkName: "component---src-pages-components-skills-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-experience-js": () => import("./../src/pages/experience.js" /* webpackChunkName: "component---src-pages-experience-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-js": () => import("./../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-resume-js": () => import("./../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

